import React, { useState } from "react";
import svg3 from "../../assets/svg/svg3.svg";
import svg4 from "../../assets/svg/svg4.svg";
import svg5 from "../../assets/svg/svg5.svg";
import { useSelector } from "react-redux";
import { cashConverter } from "../../utils";

function Card() {
  const { dashboardData } = useSelector((state) => state.fetch);
  const items = [
    {
      title: "Total Revenue",
      price: "$75,500",
      percentage: "+10%",
      svg: svg3,
      bgColor: "#1D1F2C",
      sideColor: "#3250FF",
    },
    {
      title: "Bank Balance",
      price: "$75,500",
      percentage: "+10%",
      svg: svg4,
      bgColor: "#883DCF",
      sideColor: "#F9C80E",
    },
    {
      title: "Mpesa Balance",
      price: "$75,500",
      percentage: "+10%",
      svg: svg3,
      bgColor: "#3250FF",
      sideColor: "#F9F9FC",
    },
    {
      title: "Disbursements Balance",
      price: "$75,500",
      percentage: "+10%",
      svg: svg5,
      bgColor: "#2BB2FE",
      sideColor: "#F9C80E",
    },
  ];
  const svgMap = {
    svg3,
    svg4,
    svg5,
  };
  const processedData = dashboardData?.items?.map((item) => ({
    ...item,
    svg: svgMap[item?.svg],
  }));

  const [showMpesa, setShowMpesa] = useState(false);
  const handleShow = (item) => {
    if (item === "Paybill Amount") {
      setShowMpesa(!showMpesa);
    }
  };

  return (
    <>
      <div className="mt-[.2rem] flex flex-shrink flex-wrap w-full gap-y-[1rem] gap-x-[1rem]">
        {processedData &&
          processedData?.map((item) => {
            if (( item.title === "Work Amount" || item.title === "Utility Amount" || item.title === "B2B Amount") && !showMpesa) {
              return null;
            }
            return (
              <div
                className="cursor-pointer"
                key={item.title}
                onClick={() => handleShow(item.title)}
              >
                <div
                  style={{ backgroundColor: item?.bgColor }}
                  className="lg:w-[300px] w-full h-[150px] rounded-[10px]  p-4 relative overflow-hidden shadow-lg "
                >
                  <div
                    className="absolute -top-10 -right-10 w-36 h-36   rounded-full opacity-20"
                    style={{
                      clipPath: "circle(60% at 75% 25%)",
                      backgroundColor: item?.sideColor,
                    }}
                  ></div>
                  <div className="w-[34px] h-[34px] rounded-[10px] bg-[#494B55] flex items-center justify-center">
                    <img src={item?.svg} alt="svg3" />
                  </div>

                  <div className="mt-5 label_1 !text-white">{item.title}</div>
                  <div className="flex items-center gap-x-5  mt-3">
                    <h1 className="paragraph_2 !text-white">
                      {cashConverter(item?.price)}
                    </h1>
                    <div className="bg-[#494B55] w-[40px] h-[20px] flex items-center justify-center">
                      <p className="text-orangeYellows text-sm font-medium flex items-center">
                        {item?.percentage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Card;
