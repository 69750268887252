import { Descriptions } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { dateForHumans } from "../../../utils";

function CustomerBasicDetails() {
  const { activeCustomer } = useSelector((state) => state.obj);
  const items = [
    {
      key: "0",
      label: "First Name",
      children: activeCustomer?.usrFirstname,
    },
    {
      key: "12",
      label: "Middle Name",
      children: activeCustomer?.usrMiddleName,
    },
    {
      key: "1",
      label: "Last Name",
      children: activeCustomer?.usrLastname,
    },
    {
      key: "21",
      label: "Title",
      children: activeCustomer?.usrTitle,
    },

    {
      key: "2",
      label: "National ID",
      children: activeCustomer?.usrNationalId,
    },
    {
      key: "3",
      label: "Email",
      children: activeCustomer?.usrEmail,
    },
    {
      key: "4",
      label: "Mobile Number",
      children: activeCustomer?.usrMobileNumber,
    },
 
    {
      key: "10",
      label: "Gender",
      children: activeCustomer?.usrGender,
    },
   
   
  ];

  return (
    <div> 
      <Descriptions
        column={2}
        colon={true}
        title="Customer Basic Details"
        items={items}
      />
    </div>
  );
}

export default CustomerBasicDetails;
