import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../instance";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  dropLoading:false,
  userStatus: [],
  myUsersData: [],
  usersCount: 0,
  rolesData: [],

  attachmentSetupCount: 0,
  attachmentSetup: [],

  organizationCardCount: 0,
  organizationCardData: [],
  paybills: [],
  tariff: [],
  tariffNegotiated: [],
  commissions: [],
  identities: [],
  orgWallets: [],
  subwalletData: [],
  statement: [],
  dashboardData: [],
  usersData: [],

  organizationCount: 0,
  organizationData: [],
  organizationAdminData: {},
  paybillsCount: 0,
  tariffCount: 0,
  tariffNegotiatedCount: 0,
  commissionsCount: 0,
  identitiesCount: 0,
  statementCount: 0,
  usersCount: 0,

  ratifyData: [],
  ratifyCount: 0,

  transactionData: [],
  transactionCount: 0,

  kybAlgoData: [],
  kybAlgoCount: 0,
  notificationsData: [],
  settlementData:[],
  settlementCount:0,
  orgDrop:[],
  banks:[],
  userDetails:{},
};

export const fetchUsersStatus = createAsyncThunk(
  "filterSlice/fetchUsersStatus",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v2/users/distinct-status`)
      .then((res) => res.data?.data?.result);
    return res;
  }
);

export const fetchMyUsers = createAsyncThunk(
  "saveSlice/fetch/fetchMyUsers",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRoles = createAsyncThunk("user/fetchRoles", async (data) => {
  const res = await axiosInstance
    .get(`${url}/api/v2/users/roles`)
    .then((res) => res.data?.data?.result);
  return res;
});

export const fetchAttachmentSetup = createAsyncThunk(
  "saveSlice/fetch/fetchAttachmentSetup",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganizationCard = createAsyncThunk(
  "saveSlice/fetch/fetchOrganizationCard",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "saveSlice/fetch/fetchOrganization",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchOrganizationAdmin = createAsyncThunk(
  "filterSlice/fetchOrganizationAdmin",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/api/v2/users/get-orgAdmins?orgId=${data?.orgId}`)
      .then((res) => res.data?.data?.result);
    return res;
  }
);

export const fetchPaybills = createAsyncThunk(
  "saveSlice/fetch/fetchPaybills",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTariffs = createAsyncThunk(
  "saveSlice/fetch/fetchTariffs",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTariffsNegotiated = createAsyncThunk(
  "saveSlice/fetch/fetchTariffsNegotiated",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCommissions = createAsyncThunk(
  "saveSlice/fetch/fetchCommissions",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIdentities = createAsyncThunk(
  "saveSlice/fetch/fetchIdentities",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganizationWallets = createAsyncThunk(
  "saveSlice/fetch/fetchOrganizationWallets",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSubwallets = createAsyncThunk(
  "saveSlice/fetch/fetchSubwallets",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchWalletStatement = createAsyncThunk(
  "saveSlice/fetch/fetchWalletStatement",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDashboard = createAsyncThunk(
  "saveSlice/fetch/fetchDashboard",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "saveSlice/fetch/fetchUsers",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRatify = createAsyncThunk(
  "saveSlice/fetch/fetchRatify",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTransactions = createAsyncThunk(
  "saveSlice/fetch/fetchTransactions",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchKybAlgo = createAsyncThunk(
  "saveSlice/fetch/fetchKybAlgo",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrgNotifications = createAsyncThunk(
  "saveSlice/fetch/getOrgNotifications",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      } 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchManualSettlements = createAsyncThunk(
  "saveSlice/fetch/fetchManualSettlements",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganizationDrop = createAsyncThunk(
  "saveSlice/fetch/fetchOrganizationDrop",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchBanks = createAsyncThunk(
  "saveSlice/fetch/fetchBanks",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "saveSlice/fetch/getUserDetails",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchSlice = createSlice({
  name: "fetch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchUsersStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.userStatus = action.payload;
      })
      .addCase(fetchUsersStatus.rejected, (state) => {
        state.loading = false;
        state.userStatus = [];
      })

      .addCase(fetchMyUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMyUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.myUsersData = action.payload?.data?.result;
        state.usersCount = action.payload.total;
      })
      .addCase(fetchMyUsers.rejected, (state) => {
        state.loading = false;
        state.myUsersData = [];
        state.usersCount = 0;
      })

      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesData = action.payload;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.loading = false;
        state.rolesData = [];
      })

      .addCase(fetchAttachmentSetup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAttachmentSetup.fulfilled, (state, action) => {
        state.loading = false;
        state.attachmentSetup = action.payload?.data?.result;
        state.attachmentSetupCount = action.payload.total;
      })
      .addCase(fetchAttachmentSetup.rejected, (state) => {
        state.loading = false;
        state.attachmentSetup = [];
        state.attachmentSetupCount = 0;
      })

      .addCase(fetchOrganizationCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganizationCard.fulfilled, (state, action) => {
        state.loading = false;
        state.organizationCardData = action.payload?.data?.result;
        state.organizationCardCount = action.payload.total;
      })
      .addCase(fetchOrganizationCard.rejected, (state) => {
        state.loading = false;
        state.organizationCardData = [];
        state.organizationCardCount = 0;
      })

      .addCase(fetchOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organizationData = action.payload?.data?.result;
        state.organizationCount = action.payload.total;
      })
      .addCase(fetchOrganization.rejected, (state) => {
        state.loading = false;
        state.organizationData = [];
        state.organizationCount = 0;
      })

      .addCase(fetchOrganizationAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrganizationAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.organizationAdminData = action.payload;
      })
      .addCase(fetchOrganizationAdmin.rejected, (state) => {
        state.loading = false;
        state.organizationAdminData = {};
      })

      .addCase(fetchPaybills.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaybills.fulfilled, (state, action) => {
        state.loading = false;
        state.paybills = action.payload?.data?.result;
        state.paybillsCount = action.payload.total;
      })
      .addCase(fetchPaybills.rejected, (state) => {
        state.loading = false;
        state.paybills = [];
        state.paybillsCount = 0;
      })

      .addCase(fetchTariffs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTariffs.fulfilled, (state, action) => {
        state.loading = false;
        state.tariff = action.payload?.data?.result;
        state.tariffCount = action.payload.total;
      })
      .addCase(fetchTariffs.rejected, (state) => {
        state.loading = false;
        state.tariff = [];
        state.tariffCount = 0;
      })

      .addCase(fetchTariffsNegotiated.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTariffsNegotiated.fulfilled, (state, action) => {
        state.loading = false;
        state.tariffNegotiated = action.payload?.data?.result;
        state.tariffNegotiatedCount = action.payload.total;
      })
      .addCase(fetchTariffsNegotiated.rejected, (state) => {
        state.loading = false;
        state.tariffNegotiated = [];
        state.tariffNegotiatedCount = 0;
      })

      .addCase(fetchCommissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCommissions.fulfilled, (state, action) => {
        state.loading = false;
        state.commissions = action.payload?.data?.result;
        state.commissionsCount = action.payload.total;
      })
      .addCase(fetchCommissions.rejected, (state) => {
        state.loading = false;
        state.commissions = [];
        state.commissionsCount = 0;
      })

      .addCase(fetchIdentities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIdentities.fulfilled, (state, action) => {
        state.loading = false;
        state.identities = action.payload?.data?.result;
        state.identitiesCount = action.payload.total;
      })
      .addCase(fetchIdentities.rejected, (state) => {
        state.loading = false;
        state.identities = [];
        state.identitiesCount = 0;
      })

      .addCase(fetchOrganizationWallets.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchOrganizationWallets.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.orgWallets = action.payload?.data?.result;
      })
      .addCase(fetchOrganizationWallets.rejected, (state) => {
        state.dropLoading = false;
        state.orgWallets = [];
      })

      .addCase(fetchSubwallets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubwallets.fulfilled, (state, action) => {
        state.loading = false;
        state.subwalletData = action.payload?.data?.result;
      })
      .addCase(fetchSubwallets.rejected, (state) => {
        state.loading = false;
        state.subwalletData = [];
      })

      .addCase(fetchWalletStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWalletStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.statement = action.payload?.data?.result;
        state.statementCount = action.payload.total;
      })
      .addCase(fetchWalletStatement.rejected, (state) => {
        state.loading = false;
        state.statement = [];
        state.statementCount = 0;
      })

      .addCase(fetchDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardData = action.payload?.data?.result;
      })
      .addCase(fetchDashboard.rejected, (state) => {
        state.loading = false;
        state.dashboardData = [];
      })

      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.usersData = action.payload?.data?.result;
        state.usersCount = action.payload.total;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
        state.usersData = [];
        state.usersCount = 0;
      })

      .addCase(fetchRatify.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRatify.fulfilled, (state, action) => {
        state.loading = false;
        state.ratifyData = action.payload?.data?.result;
        state.ratifyCount = action.payload.total;
      })
      .addCase(fetchRatify.rejected, (state) => {
        state.loading = false;
        state.ratifyData = [];
        state.ratifyCount = 0;
      })

      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactionData = action.payload?.data?.result;
        state.transactionCount = action.payload.total;
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.loading = false;
        state.transactionData = [];
        state.transactionCount = 0;
      })

      .addCase(fetchKybAlgo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchKybAlgo.fulfilled, (state, action) => {
        state.loading = false;
        state.kybAlgoData = action.payload?.data?.result;
        state.kybAlgoCount = action.payload.total;
      })
      .addCase(fetchKybAlgo.rejected, (state) => {
        state.loading = false;
        state.kybAlgoData = [];
        state.kybAlgoCount = 0;
      })

      .addCase(getOrgNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrgNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationsData = action?.payload?.data?.result;
      })
      .addCase(getOrgNotifications.rejected, (state) => {
        state.loading = false;
        state.notificationsData = [];
      })

      .addCase(fetchManualSettlements.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchManualSettlements.fulfilled, (state, action) => {
        state.loading = false;
        state.settlementData = action.payload?.data?.result;
        state.settlementCount = action.payload.total;
      })
      .addCase(fetchManualSettlements.rejected, (state) => {
        state.loading = false;
        state.settlementData = [];
        state.settlementCount = 0;
      })

      .addCase(fetchOrganizationDrop.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchOrganizationDrop.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.orgDrop = action.payload?.data?.result; 
      })
      .addCase(fetchOrganizationDrop.rejected, (state) => {
        state.dropLoading = false;
        state.orgDrop = []; 
      })
       
      .addCase(fetchBanks.pending, (state) => {
        state.dropLoading = true;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.dropLoading = false;
        state.banks = action.payload?.data?.result;
      })
      .addCase(fetchBanks.rejected, (state) => {
        state.dropLoading = false;
        state.banks = [];
      })

      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action?.payload?.data?.result?.[0];
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.loading = false;
        state.userDetails = {};
      })

  },
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
