import React, { useEffect, useRef, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Divider, Avatar, Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { formatImgPath } from "../../utils";
import PageHeader from "../../components/PageHeader";
import { save, saveFile } from "../../features/save/saveSlice";
import toast from "react-hot-toast";
import { getUserDetails } from "../../features/fetch/fetchSlice";

const EditAccount = ({ setIsEdit }) => {
  const { userDetails } = useSelector((state) => state.fetch);
  const { user } = useSelector((state) => state.auth);
 
  const [imageSrc, setImageSrc] = useState(formatImgPath(userDetails?.usrLogo == null? "avatar.png" : userDetails?.usrLogo));
 
  const [accProfile, setAccProfile] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { saving } = useSelector((state) => state.save);

  const handleImageChange = async (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const res = await dispatch(saveFile(formData));

    if (res?.payload?.success) {
      await updateUserInfo(res.payload?.data?.result);
      await setAccProfile(res.payload?.data?.result);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const updateUserInfo = async (item) => {
    const res = await dispatch(
      save({
        url: "api/v2/users/update",
        usrLogo: item,
        usrId:user?.usrId
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await getUserDetailsData();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function getUserDetailsData() {
    await dispatch(
      getUserDetails({
        usrId: user?.usrId,
        usrEmail: null,
        usrNationalId: null,
        url: "api/v2/users/get",
      })
    );
  }

  const onFinish = async (data) => {
    // dispatch(updateUser(allData));
    // dispatch(fetchCurrentUser(userData));
    // dispatch(fetchAllUser({ accId: accId }))
    setIsEdit(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  useEffect(()=>{
    setImageSrc(formatImgPath(userDetails?.usrLogo == null? "avatar.png" : userDetails?.usrLogo))
  },[userDetails])
  return (
    <Form
      layout="vertical"
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      form={form}
      initialValues={userDetails} 
    >
      <div className="bg-white rounded-[10px] mt-[1.25rem] h-full  w-[100%] lg:p-10 p-3">
        <PageHeader header={"Edit Profile"} />
        <Divider />

        {/* <img src='https://home.ndovupay.co.ke/file/opt/images/OTP_IMAGES/FILES/20250213113626_download.jpeg'/> */}

        <div className="relative">
          <button className="mb-10">
            <Avatar size={150} src={imageSrc} />
            <div className="absolute left-[10%] bottom-10">
              <EditOutlined />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
          </button>
        </div>

       
        <div className="my-6">
          <span className="paragraph_paragraph_bb13 ">Personal Details</span>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
          <Form.Item
            name="usrFirstname"
            label="First Name"
            rules={[
              {
                message: "please add First Name",
              },
            ]}
          >
            <Input disabled className="input" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="usrLastname"
            label="Last Name"
            rules={[
              {
                message: "Please add user last name",
              },
            ]}
          >
            <Input disabled className="input" placeholder="Last Name" />
          </Form.Item>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
          <Form.Item
            name="usrEmail"
            label="Email"
            rules={[
              {
                message: "please add Email",
              },
            ]}
          >
            <Input disabled className="input" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="usrMobileNumber"
            label="Phone Number"
            rules={[
              {
                message: "Please add Phone Number",
              },
            ]}
          >
            <Input disabled className="input" placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            name="usrNationalId"
            label="Phone Number"
            rules={[
              {
                message: "Please add Phone Number",
              },
            ]}
          >
            <Input disabled className="input" placeholder="Phone Number" />
          </Form.Item>
        </div>

        <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
          <div className="justify-start"></div>
          <div className="justify-end flex items-center gap-x-2">
            <div className="w-[200px]">
              <button
                key="back"
                type="button"
                onClick={handleCancel}
                className="cstm-btn"
              >
                Cancel
              </button>
            </div>

            <div className="w-[200px]">
              <button
                key="submit"
                type="submit"
                className="cstm-btn-2"
                disabled={saving}
              >
                {saving ? <Spin /> : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default EditAccount;
