import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg27 from "../../assets/svg/svg27.svg";
import svg38 from "../../assets/svg/svg38.svg";
import FilterModal from "./modal/FilterModal";
import CardLoading from "../../components/CardLoading";
import { deleteTariff } from "../../features/delete-setting/deleteSlice";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg";
import eyeSvg from "../../assets/svg/eye.svg";
import BreadCrumb from "../../layout/BreadCrumb"; 
import { fetchUsers } from "../../features/fetch/fetchSlice";
import Search from "../../components/Search";
import { setActiveObj } from "../../features/obj/objSlice";

export default function UsersTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usersData, loading, usersCount } = useSelector(
    (state) => state.fetch
  );

  const [searchText, setSearchText] = useState("");

  const [open, setopen] = useState(false);

  const [searchInput, setsearchInput] = useState(null);

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    dispatch(
        fetchUsers({
        url: "api/v2/users/getUsersDynamic",
        usrFirstName: e.target.value == "" ? null : e.target.value,
      })
    );
  };

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModalFilter = () => {
    setIsModalOpen(true);
  };
  const handleCancelFilter = () => {
    setIsModalOpen(false);
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await dispatch(
      fetchUsers({
        tarType: null,
        tarCode: null,
        url: "api/v1/payments/fetch-tariffs",
      })
    );
    await setFilters([]);
  };

  const [prodd, setProdd] = useState("");
  async function handleCancel() {
    await setopen(false);
  }

  const handleView = async(item) => {
    await dispatch(setActiveObj(item))
    await navigate('/user-view')
  };
 

  const columns = [
    {
      title: "First Name",
      dataIndex: "usrFirstname",
      sorter: (a, b) => a.usrFirstname.localeCompare(b.usrFirstname),
    }, 
    {
      title: "Last Name",
      dataIndex: "usrLastname",
      sorter: (a, b) => a.usrLastname.localeCompare(b.usrLastname),
    }, 
    {
      title: "National ID",
      dataIndex: "usrNationalId",
      sorter: (a, b) => a.usrNationalId - b.usrNationalId,
    }, 
    {
      title: "Email",
      dataIndex: "usrEmail",
      sorter: (a, b) => a.usrEmail.localeCompare(b.usrEmail),
    },
    {
      title: "Mobile Number",
      dataIndex: "usrMobileNumber",
      sorter: (a, b) => a.usrMobileNumber.localeCompare(b.usrMobileNumber),
    },
    {
      title: "Status",
      dataIndex: "usrStatus",
      render: (item) => {
        const colors = {
          PENDING_APPROVAL: "#FF9800",
          ACTIVE: "#28A745",
          DISABLED: "#3250FF",
          INACTIVE: "#F44336",
        };

        return (
          <div
            className="text-[#FFF] inline-block rounded-[5px] px-2 py-1 w-auto"
            style={{
              backgroundColor: colors[item] || "darkGreen",
            }}
          >
            {item === "PENDING_APPROVAL" ? "PENDING APPROVAL" : item}
          </div>
        );
      },
    },
    {
      title: "Actions",
      render: (item) => (
        <>
           <button onClick={() => handleView(item)}>
              <img src={eyeSvg} alt="eyeSvg" />
            </button>
        </>
      ),
    },
  ];

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchUsersData(page, size) {
    dispatch(
      fetchUsers({
        url: "api/v2/users/getUsersDynamic",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    fetchUsersData();
  }, []);
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: <div className="text-lighGold text-[16px]">Users</div>,
    },
  ];

  if (loading && !searchInput) return <CardLoading />;
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Users Information"}
          info={"Here’s an overview of your users"}
        />
      </div>

      <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
        <div className="flex flex-col w-full lg:px-10 px-3 ">
          <h3 className="heading_4 mt-[1.25rem]">Users Details</h3>
          <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
            {/* <div className="flex items-center">
                <span>
                  {" "}
                  <button
                    onClick={showModalFilter}
                    type="button"
                    className={`bg-transparent flex items-center gap-x-1 paragraph_1 ${
                      Object?.keys(formData)?.length > 0
                        ? "!text-[#5688E5]"
                        : "inherit"
                    }`}
                  >
                    <img src={FunnelSimple} alt="FunnelSimple" />
                    Filters
                  </button>
                </span>
                {Object?.keys(formData)?.length > 0 && (
                  <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                    :{Object?.keys(formData)?.length}
                    <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                  </span>
                )}
              </div> */}

            <div className="w-[16.8125rem]">
              <Search
                searchInput={searchInput}
                handleSearch={handleSearch}
                text={"Search user..."}
              />
            </div>
          </div>
          <div className="mb-5">
            <section>
              <Table
                rowSelection={false}
                className="mt-[1.31rem] w-full"
                scroll={{
                  x: 800,
                }}
                rowKey={(record) => record?.usrId}
                columns={columns}
                dataSource={usersData}
                loading={loading}
                pagination={{
                  position: ["bottomCenter"],
                  current: pageIndex + 1,
                  total: usersCount,
                  pageSize: pageSize,
                  onChange: (page, size) => {
                    setPageIndex(page - 1);
                    setPageSize(size);
                    fetchUsersData(page - 1, size);
                  },
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
            </section>
          </div>
        </div>
      </div>

      <FilterModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancelFilter}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
