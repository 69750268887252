import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import toast from "react-hot-toast";
import { save } from "../../../features/save/saveSlice"; 

const { TextArea } = Input;

const FinalRemarksModal = ({
  open,
  handleCancel,
  handleFetchData, 
  status,
  title
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const { saving } = useSelector((state) => state.save);
   const { activeCustomer } = useSelector((state) => state.obj);

  const onFinish = async (data) => {
    data.usrStatus = status
    data.usrId =  activeCustomer?.usrId
    const res = await dispatch(
      save({
        ...data,
        url: "api/v2/users/create-Identity",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData(); 
      await handleCancel();
      await form.resetFields(); 
      await navigate('/organization-customerApproval')
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };
 
  
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={title}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={singlePaymentObj}
        >
           
          <Form.Item
            name="message"
            label="Remarks"
            rules={[
              {
                required: true,
                message: "Give final remarks",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Give final remarks"
              className="input-textarea"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

     
    </>
  );
};
export default FinalRemarksModal;
