import React, { useEffect, useState } from "react";
import { Avatar, Badge, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import gridSvg from "../assets/svg/grid.svg";
import orgSvg from "../assets/svg/orgSvg.svg";
import { UserOutlined } from "@ant-design/icons"; 
import logo from "../assets/img/Main logo.png"; 
import settingsSvg from "../assets/svg/svg49.svg";
import customerSvg from "../assets/svg/adminSvg.svg";
import billSvg from "../assets/svg/billSvg.svg";
import homeSvg from "../assets/svg/homeSvg.svg";
import usersSvg from "../assets/svg/usersSvg.svg";
import settlSvg from "../assets/svg/settlSvg.svg";

const icons = {
  gridSvg,
  orgSvg,
  settingsSvg,
  customerSvg,
  billSvg,
  homeSvg,
  usersSvg,
  settlSvg
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export default function SideBarOpen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sideMenuCollapsed, menuData, menuLoading } = useSelector(
    (state) => state.global
  );

  const { user } = useSelector((state) => state.auth);

  const generateMenuItems = (menuData) => {
    if (user?.changePassword === true || user?.changePassword === null) {
      return [];
    }
    const mapItems = (items, isChild) => {
      return items?.map((item) => {
        const iconSrc = icons[item?.mnIcons] || gridSvg;
        return getItem(
          item?.mnName.charAt(0).toUpperCase() + item?.mnName.slice(1),
          item?.mnLink,
          !isChild ? <img src={iconSrc} alt={item?.mnIcons} /> : null,
          item?.children && item?.children?.length > 0
            ? mapItems(item?.children, true)
            : null
        );
      });
    };
    if (!menuData || menuData?.length === 0) {
      return [];
    }

    return mapItems(menuData);
  };
  const items = [
    ...generateMenuItems(menuData),

    {
      type: "divider",
    },
  ];

  const handleNavigation = (e) => {
    navigate(e.key);
  };

  const defaultOpenKeys = items
    .filter((item) => item?.mnName === "Personnel")
    .map((item) => item?.key);

  const defaultSelectedKeys = items
    .filter((item) => item?.mnName === "Dashboard")
    .map((item) => item?.key);

  const [openKeys, setOpenKeys] = useState([]);
  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.length ? keys[keys.length - 1] : "";
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {}, [sideMenuCollapsed]);

  const [chamaActive, setchamaActive] = useState(false);
  return (
    <div
      style={{
        borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
        position: "sticky",
        top: 0,
        // borderRadius: '0.875rem 0.875rem 0rem 0rem',
      }}
      className="lg:flex hidden flex-col min-h-[90vh] h-full overflow-y-scroll overflow-x-hidden mb-10 max-w-[260px] w-full bg-[#f4f5f7]  mr-5 custom-scrollbar"
    >
      {chamaActive ? (
        <>
          <div className="w-full max-w-sm bg-mainBg rounded-lg">
            <div className="card">
              <div className="cover-photo">
                <img
                  loading="lazy"
                  style={{
                    zIndex: "1000",
                  }}
                  src={logo}
                  alt="logo"
                  className="profile z-40 rounded"
                />
              </div>

              <div className="flex flex-col items-center pb-[2.19rem]">
                <span className="heading_5 mt-[19%]">name</span>
                <span className="profile_email mt-[.81rem] !text-[#98A2B3]">
                  12/12/12
                </span>

                <div
                  style={{
                    padding: "0.375rem 0rem 0.5625rem 0rem",
                  }}
                  className="rounded-[2.5rem] bg-[#F5F5F5] w-[8.25rem] flex justify-center items-center mt-[1.25rem]"
                >
                  <span className="label_4">3 members</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full max-w-sm bg-mainBg rounded-lg">
            <div className="card">
              <div className="cover-photo">
                <UserOutlined className="profile-icon" />
              </div>

              <div className="flex flex-col items-center pb-[2.19rem]">
                <span className="heading_5 mt-[19%]">{user?.userName}</span>
                <span className="profile_email mt-[1.44rem]">
                  {user?.email}
                </span>
                <span className="paragraph_2 mt-[.94rem]">
                  +{user?.usrMobileNumber}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {items?.length > 0 ? (
        <Menu
          onClick={handleNavigation}
          style={{
            width: 256,
          }}
          className=""
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          mode="inline"
          items={items}
          theme="dark"
          inlineCollapsed={sideMenuCollapsed}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
