import React, { useEffect, useState } from "react";
import { Dropdown, Form, Input, Select, Spin, Table } from "antd";
import { formatPathFile } from "../../../utils";
import useModalToggle from "../../../custom_hooks/useModalToggle";
import { useDispatch, useSelector } from "react-redux";
import TableLoading from "../../../components/TableLoading";
import { fetchAttachmentSetup } from "../../../features/fetch/fetchSlice";
import { downloadFiles } from "../../../features/global/globalSlice";
import toast from "react-hot-toast";
import { statusData } from "../../../data";
import { save } from "../../../features/save/saveSlice";
import { useNavigate } from "react-router-dom";
import FinalRemarksModal from "./FinalRemarksModal";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "select" ? (
      <Select
        className=""
        allowClear
        style={{
          width: "100%",
        }}
        options={
          statusData?.length > 0 &&
          statusData?.map((item) => ({
            value: item?.value,
            label: item?.label,
          }))
        }
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label?.toLocaleLowerCase() ?? "").includes(
            input?.toLocaleLowerCase()
          )
        }
      />
    ) : (
      <Input
        min={2}
        placeholder={"Give your remarks"}
        id="input-focus"
        className="input"
        variant="borderless"
      />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className="table-form-item"
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Field is required`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function KycDocumentsTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { open, handleCancel, handleOpen } = useModalToggle();
  const { activeCustomer } = useSelector((state) => state.obj);
  const { saving } = useSelector((state) => state.save);

  const { attachmentSetup, loading } = useSelector((state) => state.fetch);
  const { refetch, refetchKey } = useSelector((state) => state.global);

  const [editingKey, setEditingKey] = useState("");
  const [selectedObj, setselectedObj] = useState({});

  const isEditing = (record) => record?.attId === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record?.attId);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const saveFunction = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...attachmentSetup];
      const index = newData.findIndex((item) => key === item?.attId);
      if (index > -1) {
        const item = newData[index];

        const updatedObj = {
          ...item,
          ...row,
        };

        const res = await dispatch(
          save({
            ...updatedObj,
            url: `api/v2/onboard/update-user-attachment`,
          })
        );

        if (res?.payload?.success) {
          await toast.success(res?.payload?.messages?.message);
          await handleFetch();
          await cancel();
        } else {
          toast.error(res?.payload?.messages?.message);
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => edit(selectedObj)} className="pointer w-full">
          Edit
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "attAttrName",
    },
    {
      title: "Type",
      dataIndex: "attAttrType",
    },
    {
      title: "File",
      render: (item) => {
        return (
          <div
            onClick={item?.attUrl ? () => handleClick(item) : null}
            className={`cursor-pointer ${
              item?.attUrl ? "text-[#00008B]" : "text-gray-500"
            }`}
          >
            {item?.attUrl ? formatPathFile(item.attUrl) : "No File"}
          </div>
        );
      },
      // dataIndex: 'attUrl',
    },
    {
      title: "Status",
      dataIndex: "attStatus",
      editable: true,
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "attRemarks",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        const isAccepted = record?.attStatus === "ACCEPTED";
        return editable ? (
          <div className="flex w-fit items-center gap-[1rem]">
            <button onClick={cancel} className="modal_btn_light" type="button">
              Cancel
            </button>
            <button
              type="button"
              onClick={() => saveFunction(record?.attId)}
              className="modal_btn_dark"
            >
              Save
            </button>
          </div>
        ) : (
          <Dropdown
            overlayStyle={{
              width: "11.8125rem",
              margin: ".5rem",
              boxShadow: " 0px 1px 12px 0px rgba(25, 27, 35, 0.10)",
            }}
            overlayClassName="avatar_dropdown"
            arrow
            menu={{
              items: items,
            }}
            disabled={isAccepted}
            onOpenChange={(open) => {
              if (open) {
                setselectedObj(record);
              } else {
                setselectedObj({});
              }
            }}
            trigger={["click"]}
            placement="bottom"
          >
            <button type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z"
                  fill="#343330"
                />
                <path
                  d="M24.5 17.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5C23.6716 14.5 23 15.1716 23 16C23 16.8284 23.6716 17.5 24.5 17.5Z"
                  fill="#343330"
                />
                <path
                  d="M7.5 17.5C8.32843 17.5 9 16.8284 9 16C9 15.1716 8.32843 14.5 7.5 14.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5Z"
                  fill="#343330"
                />
              </svg>
            </button>
          </Dropdown>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        inputType: col.dataIndex === "attStatus" ? "select" : "input",
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleClick = async (item) => {
    const res = await dispatch(
      downloadFiles({
        attrType: item?.attAttrType,
        fileName: item?.attUrl,
      })
    );

    if (res?.payload) {
      const blob = new Blob([res.payload], {
        type: res.payload.type || "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", formatPathFile(item?.fileUrl));
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      toast.success("File downloaded successfully");
    } else {
      toast.error("Failed to download file");
    }
  };

  const allAccepted = attachmentSetup?.every(
    (item) => item?.attStatus != "SUBMITTED"
  );
  const finalStatus = attachmentSetup?.every(
    (item) => item?.attStatus === "ACCEPTED"
  ) ? "ACTIVE" : "INACTIVE";

  const handleFinalRemarks = async () => {
    handleOpen();
  };

  const handleFetch = async (page, size) => {
    await dispatch(
      fetchAttachmentSetup({
        url: "api/v2/onboard/fetch-attachments",
        attKyKey: "usrId",
        attKyValue: activeCustomer?.usrId,
      })
    );
  };

  useEffect(() => {
    if (refetchKey == 2) {
      handleFetch();
    }
  }, [refetchKey]);

  useEffect(() => {}, [attachmentSetup]);
  return (
    <>
      <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem] mt-[1.69rem]">
        <div className="white_card flex-col">
          <div className="flex flex-col w-full justify-center items-center">
            <span className="paragraph_1   text-center">
              Manage user's KYU Documents <br />
              <span className="italic">
                (You should approve and give remarks for each document)
              </span>
            </span>
          </div>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              <Form form={form} component={false}>
                <Table
                  className="mt-[1.25rem]"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  rowKey={"attId"}
                  dataSource={attachmentSetup}
                  columns={mergedColumns}
                  scroll={{
                    x: 1200,
                  }}
                />
                <div className="flex items-center justify-center w-full">
                  <div className="mt-[1.25rem] w-[220px] justify-center item-center ">
                    <button
                      className={`cstm-btn-2 ${
                        !allAccepted || attachmentSetup?.length === 0
                          ? "cursor-not-allowed !bg-[#d3d3d3]"
                          : "inherit"
                      }`}
                      type="button"
                      disabled={
                        !allAccepted || saving || attachmentSetup?.length === 0
                      }
                      onClick={handleFinalRemarks}
                    >
                      {saving ? <Spin /> : "Give final remarks"}
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
      <FinalRemarksModal
        handleFetchData={handleFetch}
        open={open}
        handleCancel={handleCancel}
        status={finalStatus}
        title={`Final remarks for ${activeCustomer?.usrFirstname}`}
      />
    </>
  );
}
