import { useDispatch, useSelector } from "react-redux";
import chamaImg from "../../../assets/img/chamaImg.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fetchOrganizationToken } from "../../../features/auth/authSlice";
import { setActiveOrganization } from "../../../features/obj/objSlice";
import { formatImgPath } from "../../../utils";
import { useEffect, useState } from "react";

export default function OrganizationListCard({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [imageSrc, setImageSrc] = useState(
    formatImgPath(
      item?.orgProfilePic == null ? "avatar.png" : item?.orgProfilePic
    )
  );

  async function handleActiveChama() {
    await dispatch(fetchOrganizationToken(item?.orgUid));
    await dispatch(setActiveOrganization(item));
    await navigate("/org-attachments");
  }
 
  useEffect(() => {
    setImageSrc(
      formatImgPath(
        item?.orgProfilePic == null ? "avatar.png" : item?.orgProfilePic
      )
    );
  }, [item]);
  return (
    <>
      <div
        onClick={handleActiveChama}
        style={{
          border: "1px solid var(--Tile-stroke, #E2E9EE)",
        }}
        className="w-[300px] flex flex-col h-full px-[.625rem] py-[2.06rem] rounded-[1.5rem] min-h-[14rem] cursor-pointer"
      >
        <div className="flex justify-between items-start w-full">
          <img
            src={imageSrc}
            className="w-[5.25rem] h-[5.25rem] rounded-full object-cover"
            alt="Chama icon"
          />

          <button className="black-round-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M4.16602 10.5001H15.8327M15.8327 10.5001L9.99935 4.66675M15.8327 10.5001L9.99935 16.3334"
                stroke="white"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <span className="heading_4 mt-[1.88rem]">{item?.orgName}</span>
        <span className="chama_card_date mt-[.75rem]">
          Joined {moment(item?.orgCreatedDate).format("Do MMM YYYY")}
        </span>
      </div>
    </>
  );
}
