import React, { useState } from "react";
import { Avatar,  Descriptions } from "antd";
import { useSelector } from "react-redux";
import {  formatImgPath } from "../../utils"; 

const MyAccount = ({ setIsEdit }) => {
  const { userDetails } = useSelector((state) => state.fetch);
  
  const itemsUser = [
    {
      key: "1",
      label: "First Name",
      children: userDetails?.usrFirstname ?? "N/A",
    },
    {
      key: "5",
      label: "Last Name",
      children: userDetails?.usrLastname ?? "N/A",
    },
    {
      key: "2",
      label: "Email",
      children: userDetails?.usrEmail ?? "N/A",
    },
    {
      key: "3",
      label: "Telephone",
      children: userDetails?.usrMobileNumber ?? "N/A",
    },
    {
      key: "4",
      label: "National ID",
      children: userDetails?.usrNationalId ?? "N/A",
    },
  ];

  return (
    <div className="flex flex-col h-screen  w-[100%] lg:px-10 px-3">
      <div className="relative flex flex-row bg-[#EDF3FB] justify-end items-center h-[15%] w-full mt-10">
        <div className="w-[200px] mr-10">
          <button onClick={() => setIsEdit(true)} className="cstm-btn-2">
            Edit profile
          </button>
        </div>
        <div className="absolute left-[5%] rounded-full bg-white top-[50%]">
          <Avatar
            size={150}
            src={formatImgPath(userDetails?.usrLogo == null? "avatar.png" : userDetails?.usrLogo)}
          />
        </div>
      </div>

      <div className="flex flex-row mt-36">
        <div className="flex lg:flex-row flex-col gap-y-10">
           

          <Descriptions
            column={1}
            colon={false}
            title="User Information"
            items={itemsUser}
          />
        </div>
      </div>
    </div>
  );
};
export default MyAccount;
