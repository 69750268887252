import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FilterModal from "./modal/FilterModal";
import svg38 from "../../assets/svg/svg38.svg";
import svg32 from "../../assets/svg/svg32.svg"; 
import NoDataTable from "../../components/NoDataTable";
import {
  fetchOrganization, 
} from "../../features/fetch/fetchSlice";
import { capitalize, dateForHumans, timeAmPm } from "../../utils"; 
import { setActiveOrganization } from "../../features/obj/objSlice";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg";
import useModalToggle from "../../custom_hooks/useModalToggle";
import { fetchOrganizationToken } from "../../features/auth/authSlice";
import CardLoading from "../../components/CardLoading";
 

export default function OrganizationApprovedTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, organizationCount, organizationData } = useSelector(
    (state) => state.fetch
  );
  const { refetchKey1 } = useSelector(
    (state) => state.global
  );
  

  const [searchText, setSearchText] = useState("");

  const { open, handleCancel, handleOpen } = useModalToggle();

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModalFilter = () => {
    handleOpen();
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await fetchOrganizationData();
    await setFilters([]);
  };

  const [prodd, setProdd] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = async () => {};

  const handleView = async (item) => { 
    await dispatch(setActiveOrganization(item));
    await navigate("/organization-approval-details");
  };

  const settingItems = [
    {
      key: "1",
      label: (
        <Link className="flex  text-[16px] font-sans  !text-lightBlack">
          Edit
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={showModalDelete}
          className="flex  text-[16px] font-sans   !text-lightBlack"
        >
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "orgName",
      sorter: (a, b) => a.orgName.localeCompare(b.orgName),
    },
    {
      title: "County",
      dataIndex: "orgCounty",
      sorter: (a, b) => a.orgCounty.localeCompare(b.orgCounty),
    },
    {
      title: "Description",
      dataIndex: "orgDesc",
      sorter: (a, b) => a.orgDesc.localeCompare(b.orgDesc),
    },
    {
      title: "Email",
      dataIndex: "orgEmail",
      sorter: (a, b) => a.orgEmail.localeCompare(b.orgEmail),
    },
    {
      title: "KRA Pin",
      dataIndex: "orgKraPin",
      sorter: (a, b) => a.orgKraPin.localeCompare(b.orgKraPin),
    },
    {
      title: "Location",
      dataIndex: "orgLocation",
      sorter: (a, b) => a.orgLocation.localeCompare(b.orgLocation),
    },
    {
      title: "Mobile",
      dataIndex: "orgMobile",
      sorter: (a, b) => a.orgMobile.localeCompare(b.orgMobile),
    },
    {
      title: "Physical Address",
      dataIndex: "orgPhysicalAdr",
      sorter: (a, b) => a.orgPhysicalAdr.localeCompare(b.orgPhysicalAdr),
    },
    {
      title: "Town",
      dataIndex: "orgTown",
      sorter: (a, b) => a.orgTown.localeCompare(b.orgTown),
    }, 
    {
      title: "Created Date",
      dataIndex: "orgCreatedDate",
      render: (item) => {
        return <div>{dateForHumans(item)}</div>;
      },
      sorter: (a, b) => a.orgCreatedDate - b.orgCreatedDate,
    },

    {
      title: "Created Time",
      dataIndex: "orgCreatedTime",
      render: (item) => {
        return <div>{timeAmPm(item)}</div>;
      },
      sorter: (a, b) => a.orgCreatedTime - b.orgCreatedTime,
    },
    {
      title: "Status",
      dataIndex: "orgStatus",
      sorter: (a, b) => a.orgStatus.localeCompare(b.orgStatus),
    },
    {
      title: "Info",
      render: (item) => (
        <>
          <button onClick={() => handleView(item)}>
            <img src={svg32} alt="svg32" />
          </button>
          {/* <Dropdown
                    overlayStyle={{
                        width: '250px',
                    }}
                    trigger={'click'}
                    menu={{ items: settingItems }}
                    placement="bottom"
                >
                    <button onClick={() => setProdd(item)}>
                      <img src={eyeSvg} alt="eyeSvg"/>
                    </button>
                </Dropdown> */}
        </>
      ),
    },
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchOrganizationData(page, size) {
    await dispatch(
      fetchOrganization({
        url: "api/v1/admin/get-dynamicOrganisations",
        orgStatus:'APPROVED',
        orgExtKybPassed:1,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }
  useEffect(() => {
    if(refetchKey1 == 2){
      fetchOrganizationData();
    } 
  }, [refetchKey1]);

 async function fetchOrganizationTokenData(){
  await dispatch(fetchOrganizationToken())
 } 

 const handleHome=async()=>{ 
  await navigate('/home')
 }


  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem] cursor-pointer" onClick={handleHome}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ), 
    },
    {
      title: capitalize("Organization Details"),
    },
  ]; 
  if (loading) return <CardLoading />;

  return (
    <div className="">
     
      {organizationData && organizationData?.length > 0 ? (
        <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
          <div className="flex flex-col w-full ">
            <h3 className="heading_4 mt-[1.25rem]">Approved Organizations </h3>
            <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
              <div className="flex items-center">
                <span>
                  {" "}
                  <button
                    onClick={showModalFilter}
                    type="button"
                    className={`bg-transparent paragraph_1 flex items-center gap-x-1 ${
                      Object?.keys(formData)?.length > 0
                        ? "!text-[#5688E5]"
                        : "inherit"
                    }`}
                  >
                    <img src={FunnelSimple} alt="FunnelSimple" />
                    Filters
                  </button>
                </span>
                {Object?.keys(formData)?.length > 0 && (
                  <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                    :{Object?.keys(formData)?.length}
                    <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                  </span>
                )}
              </div>
            </div>
            <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
              <section>
                <Table
                  rowSelection={false}
                  className="mt-[1.31rem] w-full"
                  scroll={{
                    x: 1200,
                  }}
                  rowKey={(record) => record?.orgUid}
                  columns={columns}
                  dataSource={organizationData}
                  loading={loading}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: organizationCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchOrganizationData(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                />
              </section>
            </div>
          </div>
        </div>
      ) : (
        <NoDataTable
          title=""
          imgTitle="Approved Organizations will be displayed here once available"
        />
      )}

      <FilterModal
        isModalOpen={open}
        handleCancel={handleCancel}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
