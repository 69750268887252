import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FilterModal from "./modal/FilterModal";
import svg38 from "../../assets/svg/svg38.svg"; 
import svg32 from "../../assets/svg/svg32.svg"; 
import NoDataTable from "../../components/NoDataTable";
import {
  fetchOrganization, 
} from "../../features/fetch/fetchSlice";
import { capitalize, dateForHumans, timeAmPm } from "../../utils";
import { setActiveOrganization } from "../../features/obj/objSlice";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg";
import useModalToggle from "../../custom_hooks/useModalToggle";
import { fetchOrganizationToken } from "../../features/auth/authSlice";
import CardLoading from "../../components/CardLoading";

export default function OrganizationPendingTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, organizationCount, organizationData } = useSelector(
    (state) => state.fetch
  );
  const { refetchKey1 } = useSelector((state) => state.global);
  const [searchText, setSearchText] = useState("");

  const { open, handleCancel, handleOpen } = useModalToggle();

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModalFilter = () => {
    handleOpen();
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await fetchOrganizationData();
    await setFilters([]);
  };

  const [prodd, setProdd] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = async () => {};

  const handleView = async (item) => {
    await dispatch(setActiveOrganization(item));
    await navigate("/organization-approval-details"); 
  };

  const settingItems = [
    {
      key: "1",
      label: (
        <Link className="flex  text-[16px] font-sans  !text-lightBlack">
          Edit
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={showModalDelete}
          className="flex  text-[16px] font-sans   !text-lightBlack"
        >
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "orgName",
      sorter: (a, b) => a.orgName.localeCompare(b.orgName),
    },
    {
      title: "County",
      dataIndex: "orgCounty",
      sorter: (a, b) => a.orgCounty.localeCompare(b.orgCounty),
    },
    {
      title: "Description",
      dataIndex: "orgDesc",
      sorter: (a, b) => a.orgDesc.localeCompare(b.orgDesc),
    },
    {
      title: "Email",
      dataIndex: "orgEmail",
      sorter: (a, b) => a.orgEmail.localeCompare(b.orgEmail),
    },
    {
      title: "KRA Pin",
      dataIndex: "orgKraPin",
      sorter: (a, b) => a.orgKraPin.localeCompare(b.orgKraPin),
    },
    {
      title: "Location",
      dataIndex: "orgLocation",
      sorter: (a, b) => a.orgLocation.localeCompare(b.orgLocation),
    },
    {
      title: "Mobile",
      dataIndex: "orgMobile",
      sorter: (a, b) => a.orgMobile.localeCompare(b.orgMobile),
    },
    {
      title: "Physical Address",
      dataIndex: "orgPhysicalAdr",
      sorter: (a, b) => a.orgPhysicalAdr.localeCompare(b.orgPhysicalAdr),
    },
    {
      title: "Town",
      dataIndex: "orgTown",
      sorter: (a, b) => a.orgTown.localeCompare(b.orgTown),
    },

    {
      title: "Created Date",
      dataIndex: "orgCreatedDate",
      render: (item) => {
        return <div>{dateForHumans(item)}</div>;
      },
      sorter: (a, b) => a.orgCreatedDate - b.orgCreatedDate,
    },

    {
      title: "Created Time",
      dataIndex: "orgCreatedTime",
      render: (item) => {
        return <div>{timeAmPm(item)}</div>;
      },
      sorter: (a, b) => a.orgCreatedTime - b.orgCreatedTime,
    },
    {
      title: "Status",
      dataIndex: "orgStatus",
      sorter: (a, b) => a.orgStatus.localeCompare(b.orgStatus),
    },
    {
      title: "Info",
      render: (item) => (
        <>
          <button onClick={() => handleView(item)}>
            <img src={svg32} alt="svg32" />
          </button>
          
        </>
      ),
    },
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchOrganizationData(page, size) {
    await dispatch(
      fetchOrganization({
        url: "api/v1/admin/get-dynamicOrganisations",
        orgStatus: "PENDING_APPROVAL",
        orgExtKybPassed:0,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    if (refetchKey1 == 1) {
      fetchOrganizationData();
    }
  }, [refetchKey1]);

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  async function fetchOrganizationTokenData() {
    await dispatch(fetchOrganizationToken());
  }

  const handleHome = async () => {
    await navigate("/home");
  };
 
  if (loading) return <CardLoading />;

  return (
    <div className="">
      {organizationData && organizationData?.length > 0 ? (
        <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
          <div className="flex flex-col w-full  ">
            <h3 className="heading_4 mt-[1.25rem]">Pending Organizations  </h3>
            <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
              <div className="flex items-center">
                <span>
                  {" "}
                  <button
                    onClick={showModalFilter}
                    type="button"
                    className={`bg-transparent paragraph_1 flex items-center gap-x-1 ${
                      Object?.keys(formData)?.length > 0
                        ? "!text-[#5688E5]"
                        : "inherit"
                    }`}
                  >
                    <img src={FunnelSimple} alt="FunnelSimple" />
                    Filters
                  </button>
                </span>
                {Object?.keys(formData)?.length > 0 && (
                  <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                    :{Object?.keys(formData)?.length}
                    <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                  </span>
                )}
              </div>
            </div>
            <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
              <section>
                <Table
                  rowSelection={false}
                  className="mt-[1.31rem] w-full"
                  scroll={{
                    x: 1200,
                  }}
                  rowKey={(record) => record?.orgUid}
                  columns={columns}
                  dataSource={organizationData}
                  loading={loading}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: organizationCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchOrganizationData(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                />
              </section>
            </div>
          </div>
        </div>
      ) : (
        <NoDataTable
          title=""
          imgTitle="Organizations pending approval will be displayed here once available"
        />
      )}

      <FilterModal
        isModalOpen={open}
        handleCancel={handleCancel}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
