import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL; 

const initialState = {
    deleteloading: false,
   
};

 

export const deletePaybill = createAsyncThunk(
    "staffSettingSlice/deletePaybill",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/deletePaybill/${data?.pbUid}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteTariff = createAsyncThunk(
    "staffSettingSlice/deleteTariff",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/deleteTariff/${data?.tarUid}`)
        .then((res) => res.data);
      return res;
    }
  );

  export const deleteTariffNegotiated = createAsyncThunk(
    "staffSettingSlice/deleteTariffNegotiated",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/payments/tariff-negotiated/deleteNegotiatedTariff/${data?.tarUid}`)
        .then((res) => res.data);
      return res;
    }
  );


  export const deleteNotification = createAsyncThunk(
    "staffSettingSlice/deleteNotification",
    async (data) => {
      const res = await axiosInstance
        .delete(`${url}/api/v1/notification/${data?.notId}`)
        .then((res) => res.data);
      return res;
    }
  );


export const deleteSlice = createSlice({
    name: 'delete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
 
        
        .addCase(deletePaybill.pending, (state) => {
          state.deleteloading = true;
        })
        .addCase(deletePaybill.fulfilled, (state,action) => {
          state.deleteloading = false;
        })
        .addCase(deletePaybill.rejected, (state) => {
          state.deleteloading = false;
        })

        .addCase(deleteTariff.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteTariff.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteTariff.rejected, (state) => {
            state.deleteloading = false;
          })

          .addCase(deleteTariffNegotiated.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteTariffNegotiated.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteTariffNegotiated.rejected, (state) => {
            state.deleteloading = false;
          })


          .addCase(deleteNotification.pending, (state) => {
            state.deleteloading = true;
          })
          .addCase(deleteNotification.fulfilled, (state,action) => {
            state.deleteloading = false;
          })
          .addCase(deleteNotification.rejected, (state) => {
            state.deleteloading = false;
          })
            
            
          
        
  
    },
});

export default deleteSlice.reducer;
export const {} = deleteSlice.actions;
