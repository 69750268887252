import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { paymentMethodsData } from "../../../data";
import {
  fetchBanks,
  fetchOrganizationDrop,
  fetchOrganizationWallets,
} from "../../../features/fetch/fetchSlice";
import toast from "react-hot-toast";
import { save } from "../../../features/save/saveSlice";
import ConfirmSubmitModal from "../../../components/ConfirmSubmitModal";

const { TextArea } = Input;

const SettlementModal = ({
  open,
  handleCancel,
  handleFetchData,
  setFormData,
  setopenSingle
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orgDrop, dropLoading, orgWallets, banks } = useSelector(
    (state) => state.fetch
  );
  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

   const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleOpenConfirmation = async () => {
      await setOpenConfirmation(true);
      await setopenSingle(false)
    };

  const [show, setShow] = useState("");
  const [showWallet, setShowWallet] = useState("");

  async function handleReturnPeriod(){
    await setopenSingle(true)
    await setOpenConfirmation(false);
  }

  async function handleCancelSingle() {
    await handleCancel();
    await form.resetFields();
    await setShow();
  }
  const [bankCode, setBankCode] = useState();
  const [accntNumber, setAccntNumber] = useState();
  async function handleValidValidate() {
    if (!bankCode) {
      toast.error("Please select a bank");
      return;
    }
    if (!accntNumber) {
      toast.error("Please provide a bank number");
      return;
    }
    if (bankCode && accntNumber) {
      const res = await dispatch(
        save({
          url: "api/v2/manualPayment/validate-account",
          bankCode: bankCode,
          beneficiaryAccount: accntNumber,
        })
      );
      if (res?.payload?.data?.result?.status === "SUCCESS") {
        form.setFieldsValue({
          manualPaymentBeneficiaryDetails: res?.payload?.data?.result?.benName,
        });
      } else {
        await form.setFieldsValue({
          manualPaymentBeneficiaryDetails: null,
        });
        await toast.error("Account not found type the account name");
      }
    }
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        ...data,
        url: "api/v2/manualPayment/create",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData();
      await setFormData({});
      await handleCancel();
      await form.resetFields();
      await setOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchOrganizationDropData() {
    await dispatch(
      fetchOrganizationDrop({
        url: "api/v1/admin/get-dynamicOrganisations",
        orgStatus: "APPROVED",
      })
    );
  }
  async function fetchOrganizationWalletData(id) {
    await dispatch(
      fetchOrganizationWallets({
        url: "api/v1/wallet/subWallet",
        walOrgUid: id,
      })
    );
  }

  async function fetchBanksData() {
    await dispatch(
      fetchBanks({
        url: "api/v1/payments/fetch-banks",
      })
    );
  }
  
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Manual Settlement`}
        open={open}
        onOk={onFinish}
        onCancel={handleCancelSingle}
        width={850}
        maskClosable={false}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={singlePaymentObj}
        >
          <div className="grid lg:grid-cols-2 lg:gap-x-5 grid-cols-1">
            <Form.Item
              name="manualPaymentType"
              className=""
              label={<span>Payment Source</span>}
              rules={[
                {
                  required: true,
                  message: "Please select payment type",
                },
              ]}
            >
              <Select
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                options={
                  paymentMethodsData?.length > 0 &&
                  paymentMethodsData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))
                }
                onChange={(value) => {
                  setShow(value);
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="manualPaymentOrgUid"
              className=""
              label={<span>Organization</span>}
              rules={[
                {
                  required: true,
                  message: "Please select organization",
                },
              ]}
            >
              <Select
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                options={
                  orgDrop?.length > 0 &&
                  orgDrop?.map((item) => ({
                    value: item?.orgUid,
                    label: item?.orgName,
                  }))
                }
                onChange={(value) => {
                  setShowWallet(true);
                  fetchOrganizationWalletData(value);
                  form.setFieldsValue({ manualPaymentWalUid: "" });
                  
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                onDropdownVisibleChange={() => {
                  fetchOrganizationDropData();
                }}
                dropdownRender={(menu) => (
                  <>
                    {dropLoading && (
                      <div
                        style={{
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        <Spin className="sms-spin" />
                      </div>
                    )}
                    {menu}
                  </>
                )}
              />
            </Form.Item>
            {showWallet && (
              <Form.Item
                name="manualPaymentWalUid"
                className=""
                label={<span>Wallet</span>}
                rules={[
                  {
                    required: true,
                    message: "Please select payment wallete",
                  },
                ]}
              >
                <Select
                  className=""
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  options={
                    orgWallets?.length > 0 &&
                    orgWallets?.map((item) => ({
                      value: item?.walUid,
                      label:
                        item?.walName +
                        " - " +
                        item?.walCurrency +
                        " " +
                        item?.walAmount,
                    }))
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                 
                />
              </Form.Item>
            )}

            <Form.Item
              name="manualPaymentAmount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Enter amount",
                },
              ]}
            >
              <InputNumber
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                placeholder="Enter amount"
                className="input flex"
              />
            </Form.Item>
          </div>

          {show === "MPESA" ? (
            <>
              {" "}
              <Form.Item
                name="manualPaymentNatId"
                label="National ID"
                rules={[
                  {
                    required: false,
                    message: "Recepient National ID is required",
                  },
                  {
                    validator(_, value) {
                      if (value && value.toString().length < 6) {
                        return Promise.reject(
                          "National ID must be at least 6 characters long"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                  className="input flex"
                />
              </Form.Item>
              <Form.Item
                className="table-form-item"
                name="manualPaymentReceiverMobileNo"
                label="Receiver Number"
                rules={[
                  {
                    required: true,
                    message: "Please add Receiver Number",
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length === 12) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Phone number must be exactly 10 characters")
                      );
                    },
                  },
                ]}
              >
                <PhoneInput
                  country="ke"
                  countryCodeEditable={false}
                  className="input"
                />
              </Form.Item>
            </>
          ) : show === "BANK" ? (
            <>
              <div className="flex items-center lg:gap-x-5">
                <Form.Item
                  name="manualPaymentBeneficiaryBank"
                  className="w-full"
                  label={<span>Bank</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please select Bank",
                    },
                  ]}
                >
                  <Select
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    options={
                      banks?.length > 0 &&
                      banks?.map((item) => ({
                        value: item?.bnkUid,
                        label: item?.bnkName,
                      }))
                    }
                    onChange={(value) => {
                      const selectedItem = banks?.find(
                        (item) => item?.bnkUid === value
                      );
                      setBankCode(selectedItem?.bnkNo || "");
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      fetchBanksData();
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {dropLoading && (
                          <div
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Spin className="sms-spin" />
                          </div>
                        )}
                        {menu}
                      </>
                    )}
                  />
                </Form.Item>

                <Form.Item
                  className="w-full"
                  name="manualPaymentBeneficiaryAccount"
                  label="Account Number"
                  rules={[
                    {
                      required: true,
                      message: "Enter Account Number",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setAccntNumber(e.target.value);
                    }}
                    className="input"
                  />
                </Form.Item>

                <div className="w-[100px]">
                  <button
                    type="button"
                    className="cstm-btn-2 !rounded-[10px]"
                    disabled={saving}
                    onClick={handleValidValidate}
                  >
                    {saving ? <Spin /> : "Validate"}
                  </button>
                </div>
              </div>
              <Form.Item
                name="manualPaymentBeneficiaryDetails"
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Enter Account Name",
                  },
                ]}
              >
                <Input disabled={saving} className="input" />
              </Form.Item>
            </>
          ) : null}

          <Form.Item
            name="manualPaymentRemark"
            label="Reason for transfer"
            rules={[
              {
                required: true,
                message: "Enter reason for transfer",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Enter reason for transfer"
              className="input-textarea"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancelSingle}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Next"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      <ConfirmSubmitModal
       isModalOpen={openConfirmation}
       setIsModalOpen={setOpenConfirmation}
       handleSubmit={onFinish}
       loading={saving}
       content={"Are you sure you want to make this manual payment?"}
       subContent={"Once you proceed no going back"}
       back={true}
       handleReturnPeriod={handleReturnPeriod}
       />
    </>
  );
};
export default SettlementModal;
